import React from 'react'
import SEO from '../components/seo/seo'
import Disclaimer from '../components/disclaimer/disclaimer'
import { StaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

const PersonalInjuryPage = () => (
  <>
    <SEO 
      title="Services" 
      description="Attorney Jim Feleen is a personal injury lawyer in Claremont, NH. Legal representation in accidents, injuries, crashes and more. 603-504-6507"
      keywords={['lawyer', 'law office', 'attorney', 'James Feleen', 'Jim Feleen', 'Claremont', 'NH', 'New Hampshire', 'Claremont NH', 'personal injury', 'accident', 'insurance claim', 'negligence', 'medical malpractice', 'work injuries']} />
    <Banner />
    <div className="body-wrapper">
      <h2>Personal Injury</h2>
      <h4>If you’ve been injured in a car accident or at work, Attorney Jim Feleen can represent you in personal injury legal matters.</h4>
      <p>
        The Law Office of James G. Feleen offers experienced representation in personal injury law in the Claremont NH and Sullivan County area. Whether you’re facing injuries sustained in a car accident, a work-related incident, or you feel that your insurance company or doctor haven’t treated you fairly, we can help fight for your legal rights.
      </p>
      <p>
        If you’ve been injured or damaged by someone, <Link to="/contact/">contact</Link> Attorney Jim Feleen for a free consultation. Our fees are contingent on a successful outcome for you.
      </p>     
    </div>
    <Disclaimer />
  </>
)

const Banner = props => (
  <StaticQuery
    query={query}
    render={data => (
      <div className="body-wrapper">
        <Img alt="Car that has been damaged in a accident" className="hero-image" fluid={data.file.childImageSharp.fluid}/>
      </div>
    )}
  />
);

const query = graphql`
  query {
    file(relativePath: { eq: "crashed-car-banner.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 90) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default PersonalInjuryPage
